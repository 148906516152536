<template>
    <div class="download-box">
        <div class="app">
            <p class="title">酷学移动客户端</p>
            <p class="subheading">致力于提供便捷高效的学习体验</p>
            <div class="btns">
                <el-button type="success" @click="downLoad(0)">iOS iPad下载</el-button>
                <el-button type="success" style="margin-left: 30px;" @click="downLoad(1)">Android Pad下载</el-button>
            </div>
        </div>
        <div class="card">
            <div v-for="(item, index) in cardData" :key="index" class="card-item">
                <img style="width: 80px; height: 80px;" :src="item.icon" alt="">
                <p class="card-name">{{ item.name }}</p>
                <p class="card-desc">{{ item.desc }}</p>

            </div>
        </div>
    </div>
</template>
  
<script>

export default {
    data() {

        return {
            cardData: [
                {
                    icon: require('@/assets/img/appdownload/book.png'),
                    name: '丰富多样的内容形式',
                    desc: '移动客户端可以承载图文、视频等多种多媒体教学内容，让学习过程更加生动有趣。'
                },
                {
                    icon: require('@/assets/img/appdownload/trace.png'),
                    name: '便捷的资源管理和更新',
                    desc: '云端同步使得用户在客户端便捷管理学习资料，并自动获取版本更新和最新教育资源。'
                },
                {
                    icon: require('@/assets/img/appdownload/collection.png'),
                    name: '随时随地学习',
                    desc: '用户通过移动设备随时随地学习，极大提升了学习便利性和灵活性。'
                },

            ]

        }
    },
    created() {

    },
    methods: {
        downLoad(num) {
            if (num) {
                let url = `ipad.apk`
                const a = document.createElement('a')
                a.href = url
                a.download = '酷学ipad.apk' // 下载后文件名
                a.style.display = 'none'
                document.body.appendChild(a)
                a.click() // 点击下载
                document.body.removeChild(a) // 下载完成移除元素
            } else {
                this.$message.error('暂无ios安装包，敬请期待')
            }
        }
    }
}
</script>
  
<style lang="less" scoped>
.download-box {
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
    height: 895px;
    background-image: url('../assets/img/appdownload/bgi.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;

    .app {
        width: 1200px;
        height: 249px;
        margin: 120px auto;

        .title {
            font-size: 52px;
            font-weight: 500;
            color: #1F1F1F;
            line-height: 73px;
        }

        .subheading {
            margin-top: 20px;
            font-size: 20px;
            font-weight: 400;
            color: #808080;
            line-height: 28px;
        }

        .btns {
            margin-top: 70px;
        }
    }

    .card {
        width: 1300x;
        margin: 230px auto;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        .card-item {
            width: 305px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .card-name {
            font-size: 20px;
            margin: 12px 0px;
            font-weight: 600;
            color: #1F1F1F;
            line-height: 28px;
        }

        .card-desc {
            width: 305px;
            font-size: 16px;
            font-weight: 400;
            color: #808080;
            line-height: 22px;
        }
    }

    ::v-deep {
        .el-button--success {
            background-color: #0FB09B;
            box-shadow: 0px 2px 10px 0px rgba(15, 176, 155, 0.3);
            padding: 0 60px;
            height: 58px;
            border: none;
            font-size: 20px;
            border-radius: 12px;
        }

    }
}
</style>
  